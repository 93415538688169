import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Table, Spinner, Alert, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Click2payTable() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  // Define fetchOrders using useCallback to memoize it
  const fetchOrders = useCallback(async () => {
     if (userInfo && (userInfo.isAdmin || userInfo.isOffice))
       try {
         const response = await axios.get('/api/clic2pay/order-statuses');

         // Remove duplicates based on orderId and keep only the latest entry
         const uniqueOrders = response.data.reduce((acc, current) => {
           const x = acc.find((item) => item.orderId === current.orderId);
           if (!x) {
             return acc.concat([current]);
           } else {
             return acc.map((item) =>
               item.orderId === current.orderId ? current : item
             );
           }
         }, []);
         setOrders(uniqueOrders);
         setLoading(false);
       } catch (error) {
         if (error.response && error.response.status === 401) {
           navigate('/login'); // Redirect to login page on unauthorized
         } else {
           setError('Error fetching order statuses');
           console.error('Error fetching order statuses:', error.message);
         }
         setLoading(false);
       }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Add userInfo and navigate as dependencies

  useEffect(() => {
    fetchOrders();
    const interval = setInterval(fetchOrders, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [fetchOrders]); // Use the memoized fetchOrders function as a dependency

  if (loading)
    return (
      <Spinner animation='border' role='status' className='my-3'>
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    );
  if (error) return <Alert variant='danger'>{error}</Alert>;

  return (
    <Container>
      <h2 className='my-4'>Table Status de Paiement Click2Pay </h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Montant</th>
            <th>Client</th>
            <th>Error Code</th>
            <th>Error Message</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.orderId}>
              <td>{order.orderId}</td>
              <td>{order.OrderStatus}</td>
              <td>{(order.Amount / 1000).toFixed(2)} DT</td>
              <td>{order.cardholderName}</td>
              <td>{order.ErrorCode}</td>
              <td>{order.ErrorMessage}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default Click2payTable;
