import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import { HelmetProvider } from 'react-helmet-async';

import { Provider } from 'react-redux';
import store from './store';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/bootstrap.custom.css';
//import './assets/styles/index.css';

import App from './App';
//import reportWebVitals from './reportWebVitals';

import PrivateRoute from './components/PrivateRoute';
import Meet from './Meet';

import HomeScreenPresential from './screens/HomeScreenPresential';
import ProductScreenPresential from './screens/ProductScreenPresential';

import HomeScreenOnLine from './screens/HomeScreenOnLine';
import ProductScreenOnLine from './screens/ProductScreenOnLine';

import HomeScreenOtherProducts from './screens/HomeScreenOtherProducts';
import ProductScreenOtherProducts from './screens/ProductScreenOtherProducts';

import ProfileScreen from './screens/ProfileScreen';

import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import CheckoutStripe from './components/stripe/CheckoutStripe';
import SuccessStripe from './components/stripe/SuccessStripe';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreenClicToPay from './screens/OrderScreenClicToPay';
import OrderScreenPayPal from './screens/OrderScreenPaypal';
import OrderScreenStripe from './screens/OrderScreenStripe';

import PaymentSuccessfulScreen from './screens/PaymentSuccessfulScreen';
import PaymentFailedScreen from './screens/PaymentFailedScreen';

import AdminRoute from './components/AdminRoute';
import OrderListScreen from './screens/admin/OrderListScreen';
//NEW-VENTE
import OfficeRoute from './components/OfficeRoute';
import OrderListScreenOffice from './screens/office/OrderListScreenOffice';

import ProductListScreen from './screens/admin/ProductListScreen';
//NEW-VENTE
import ProductListScreenOffice from './screens/office/ProductListScreenOffice';
import ProductListScreenFormateur from './screens/formateur/ProductListScreenFormateur';
import ProductListScreenVendeur from './screens/vendeur/ProductListScreenVendeur';

import ProductEditScreen from './screens/admin/ProductEditScreen';
import ProductEditScreenOffice from './screens/office/ProductEditScreenOffice';
import ProductEditScreenFormateur from './screens/formateur/ProductEditScreenFormateur';
import ProductEditScreenVendeur from './screens/vendeur/ProductEditScreenVendeur';

import Click2payTable from './screens/admin/Click2payTable';

import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import CertificateGenerator from './components/CertificateGenerator';
import CertificateTable from './components/CertificateTable';

//role
import FormateurRoute from './components/FormateurRoute';
//NEW-VENDEUR
import VendeurRoute from './components/VendeurRoute';

import Playlist from './components/Playlist';
import Videos from './components/Video';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/' element={<App />}>
        <Route index={true} path='/' element={<HomeScreenPresential />} />
        <Route path='/page/:pageNumber' element={<HomeScreenPresential />} />
        <Route path='/search/:keyword' element={<HomeScreenPresential />} />

        {/* <Route
          path='/click2paytable'
          element={<Click2payTable />}
          exact
        /> */}
        {/* <Route path="/presential" element={<HomeScreenPresential/>} /> */}
        <Route path='/online' element={<HomeScreenOnLine />} />
        <Route path='/other' element={<HomeScreenOtherProducts />} />

        <Route
          path='/product/presential/:id'
          element={<ProductScreenPresential />}
        />
        <Route path='/product/online/:id' element={<ProductScreenOnLine />} />
        <Route
          path='/product/other/:id'
          element={<ProductScreenOtherProducts />}
        />

        <Route path='/cart' element={<CartScreen />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/register' element={<RegisterScreen />} />
        {/* <Route path="/shipping" element={<ShippingScreen />} /> */}

        <Route path='/checkoutstripe' element={<CheckoutStripe />} />
        <Route path='' element={<PrivateRoute />}>
          <Route path='/shipping' element={<ShippingScreen />} />
          <Route path='/payment' element={<PaymentScreen />} />
          <Route path='/placeorder' element={<PlaceOrderScreen />} />
          <Route
            path='/orderClicToPay/:id'
            element={<OrderScreenClicToPay />}
          />
          <Route path='/orderPayPal/:id' element={<OrderScreenPayPal />} />
          <Route path='/orderStripe/:id' element={<OrderScreenStripe />} />
          <Route path='/profile' element={<ProfileScreen />} />
        </Route>

        <Route path='' element={<AdminRoute />}>
          <Route path='/admin/orderlist' element={<OrderListScreen />} />
          <Route path='/admin/productlist' element={<ProductListScreen />} />
          <Route
            path='/admin/productlist/:pageNumber'
            element={<ProductListScreen />}
          />
          <Route
            path='/admin/product/:id/edit'
            element={<ProductEditScreen />}
          />
          <Route path='/admin/userlist' element={<UserListScreen />} />
          <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
          <Route path='/admin/cert' element={<CertificateGenerator />} exact />
          <Route
            path='/admin/verifycert'
            element={<CertificateTable />}
            exact
          />
          {/* <Route path='/meet' element={<Meet />} />
        <Route path='/meet/:roomID' element={<Meet />} /> */}
          <Route path='/admin/playlist' element={<Playlist />} />
          <Route
            path='/admin/click2paytable'
            element={<Click2payTable />}
            exact
          />

          {/* <Route
          path='/admin/Click2paytable'
          element={<Click2payTable />}
          exact
        /> */}
          <Route
            path='/admin/playlists/:playlistId/videos'
            element={<Videos />}
          />
        </Route>

        <Route path='' element={<OfficeRoute />}>
          <Route
            path='/office/productlist'
            element={<ProductListScreenOffice />}
          />
          <Route
            path='/office/productlist/:pageNumber'
            element={<ProductListScreenOffice />}
          />

          <Route path='/office/orderlist' element={<OrderListScreenOffice />} />

          <Route
            path='/office/product/:id/edit'
            element={<ProductEditScreenOffice />}
          />

          <Route path='/office/cert' element={<CertificateGenerator />} exact />
          <Route
            path='/office/verifycert'
            element={<CertificateTable />}
            exact
          />
          {/* <Route path='/office/playlist' element={<Playlist />} /> */}
          {/* <Route
          path='/office/playlists/:playlistId/videos'
          element={<Videos />}
        /> */}
          <Route
            path='/office/click2paytable'
            element={<Click2payTable />}
            exact
          />
        </Route>

        <Route path='' element={<FormateurRoute />}>
          <Route
            path='/formateur/productlist'
            element={<ProductListScreenFormateur />}
          />
          <Route
            path='/formateur/productlist/:pageNumber'
            element={<ProductListScreenFormateur />}
          />

          <Route
            path='/formateur/product/:id/edit'
            element={<ProductEditScreenFormateur />}
          />

          <Route path='/formateur/playlist' element={<Playlist />} />
          <Route
            path='/formateur/playlists/:playlistId/videos'
            element={<Videos />}
          />
          <Route path='/meet' element={<Meet />} />
          <Route path='/meet/:roomID' element={<Meet />} />
        </Route>

        <Route path='' element={<VendeurRoute />}>
          <Route
            path='/vendeur/productlist'
            element={<ProductListScreenVendeur />}
          />
          <Route
            path='/vendeur/productlist/:pageNumber'
            element={<ProductListScreenVendeur />}
          />
          <Route
            path='/vendeur/product/:id/edit'
            element={<ProductEditScreenVendeur />}
          />
        </Route>
      </Route>
      <Route path='/successstripe' element={<SuccessStripe />} />
      <Route path='/Success' element={<PaymentSuccessfulScreen />} />
      <Route path='/Fail' element={<PaymentFailedScreen />} />
    </>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PayPalScriptProvider deferLoading={true}>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);
